<template>
  <v-container
    ><v-parallax dark class="mb-5" :src="require('@/assets/material2.jpg')"
      ><v-row align="center" justify="center"
        ><v-col class="text-lg-left" cols="12" lg="7" offset="0" offset-lg="1"
          ><h1 class="text-h5 text-lg-h2 text-sm-h4 font-weight-thin">
            您好，现邀请您参加天津市卫健委面向全市各医疗机构发起的问卷调查，请您根据本医疗机构现有院内制剂实际情况详细填写本问卷。
          </h1></v-col
        ><v-col class="text-center" cols="12" lg="4">
          <h4 class="subheading font-weight-regular mb-6">
            问卷为两部分，请全部填写，感谢您的配合
          </h4>
          <div class="d-flex justify-center mb-1">
            <v-img
              :src="require('@/assets/code.png')"
              max-height="170"
              class=""
              :aspect-ratio="1"
              contain
            />
          </div>
          <div>遇到问题请加群咨询客服</div></v-col
        ></v-row
      ></v-parallax
    >
    <v-stepper v-model="e1" alt-labels
      ><v-stepper-header>
        <v-stepper-step editable step="机"> 医疗机构信息 </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="剂">
          制剂品种信息
        </v-stepper-step> </v-stepper-header
      ><v-stepper-items
        ><v-stepper-content step="机"> <hello-world-1 /></v-stepper-content
        ><v-stepper-content step="剂"
          ><hello-world-2 /></v-stepper-content></v-stepper-items
    ></v-stepper>
  </v-container>
</template>

<script>
import HelloWorld1 from "../components/HelloWorld1";
import HelloWorld2 from "../components/HelloWorld2";
// import code from "@/assets/%E5%8A%A0%E5%85%A5%E7%BE%A4%E8%81%8A%E4%BA%8C%E7%BB%B4%E7%A0%81.png";

export default {
  data: () => ({ e1: "机" }),
  created() {
    // console.log(code);
  },
  name: "Home",
  components: {
    HelloWorld1,
    HelloWorld2,
  },
};
</script>
<style>
ol {
  list-style-type: none;
}
</style>
