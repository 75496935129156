"use strict";

import Vue from "vue";
import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL:
    process.env.baseURL ||
    process.env.apiUrl ||
    "https://api.tjzyefy.com" ||
    "http://localhost:3000",
  // timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    NProgress.start();
    // console.log("sessionStorage", sessionStorage);
    // Do something before request is sent
    if (!config.url.endsWith("/login")) {
      if (sessionStorage.getItem("token") != null) {
        const token = "Bearer " + sessionStorage.getItem("token");
        config.headers.common["Authorization"] = token;
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    NProgress.done();
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  // console.log(options);
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
