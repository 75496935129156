<template>
  <div>
    <!-- {{ JSON.stringify(preparations) }} -->
    <v-dialog width="500" :value="false" v-model="dialog"
      ><template v-slot:activator="{ on, attrs }"
        ><v-card
          v-if="JSON.stringify(preparations) == '[]'"
          color="primary"
          dark
          class="d-flex align-center mb-6"
          height="200"
          v-bind="attrs"
          v-on="on"
          @click="
            editedIndex = -1;
            editItems = Object.assign({}, defaultItems);
            $nextTick(() => {
              $refs.form.resetValidation();
            });
          "
          ><div class="text-h2 flex-grow-1 text-center font-weight-thin">
            + 添加
          </div></v-card
        ></template
      ><v-card
        ><v-card-title>
          编辑<v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        ><v-form ref="form" @submit.prevent="submit"
          ><v-card-text>
            <!-- {{ editedIndex }}|||{{ editItems }} -->
            <v-text-field
              label="1. 制剂名称"
              v-model="editItems.zjmc"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-text-field
              label="2. 制剂名称"
              persistent-hint
              hint="备案"
              v-model="editItems.zjmcb"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-radio-group
              label="3. 制剂类别"
              mandatory
              v-model="editItems.zjlb"
              :rules="[(v) => !!v || '此项必填']"
              ><v-radio
                v-for="n in [
                  { name: '化', value: 'H' },
                  { name: '中', value: 'Z' },
                ]"
                :key="n.value"
                :label="`${n.name}药`"
                :value="n.value"
            /></v-radio-group>

            <v-text-field
              label="4. 功能主治"
              v-model="editItems.gnzz"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-combobox
              :rules="[(v) => v.length > 0 || '此项必填']"
              v-model="editItems.cf"
              label="5. 成份"
              multiple
              chips
              hint="列出全部药味，每输入一位药按一下tab键或回车键（手机操作输入换行）用以区分每位药。"
            />

            <v-text-field
              label="6. 剂型"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.jx"
            />

            <v-text-field
              label="7. 规格"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.gg"
            />

            <v-text-field
              label="8. 用法用量"
              v-model="editItems.yfyl"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-text-field
              label="9. 不良反应"
              v-model="editItems.blfy"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-text-field
              label="10. 注意事项"
              v-model="editItems.zysx"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-text-field
              label="11. 特殊人群用药"
              v-model="editItems.tsrq"
              hint="包括孕妇及哺乳期妇女、儿童、老年用药等"
              :rules="[(v) => !!v || '此项必填']"
            />

            <v-text-field
              label="12. 包装"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.bz"
            />

            <v-text-field
              label="13. 贮藏"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.zc"
            />

            <v-text-field
              label="14. 有效期"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.yxq"
            />

            <v-text-field
              label="15. 执行标准"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.zxbz"
            />

            <v-text-field
              label="16. 批准文号/备案号"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.pzwh"
            />

            <v-text-field
              label="17. 价格"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.jg"
            />

            <v-switch
              :label="`18. ${editItems.ybml ? '已' : '没'}进入医保目录`"
              v-model="editItems.ybml"
            />

            <v-text-field
              label="19. 最大年产量"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.nclmax"
            />

            <v-text-field
              label="20. 最大年销量"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.nxlmax"
            />

            <v-text-field
              label="21. 平均年销量"
              :rules="[(v) => !!v || '此项必填']"
              v-model="editItems.nxl"
            />

            <v-switch
              :label="`22. 本品种${editItems.sfjy ? '' : '不'}建议在其他医疗机构调剂`"
              v-model="editItems.sfjy"
            /> </v-card-text
          ><v-card-actions
            ><v-btn block x-large color="primary" dark type="submit"
              >保存</v-btn
            ></v-card-actions
          ></v-form
        ></v-card
      ></v-dialog
    >
    <v-data-iterator :items="preparations" :items-per-page="11"
      ><template v-slot:default="{ items }"
        ><v-row
          ><v-col cols="12" md="3"
            ><v-card
              color="primary"
              dark
              class="d-flex align-center"
              height="200"
              @click="
                editedIndex = -1;
                editItems = Object.assign({}, defaultItems);
                dialog = true;
                $nextTick(() => {
                  $refs.form.resetValidation();
                });
              "
              ><div class="text-h2 flex-grow-1 text-center font-weight-thin">
                + 添加
              </div></v-card
            ></v-col
          ><v-col cols="12" md="3" v-for="item in items" :key="item.id"
            ><v-card>
              <v-card-title v-if="item.json">{{ item.json.zjmc }}</v-card-title>
              <v-card-text>{{ item.json }}</v-card-text
              ><v-card-actions
                ><v-spacer /><v-btn
                  color="primary"
                  @click="
                    editedIndex = preparations.indexOf(item);
                    axios.delete('preparation/' + item.id).then(() => {
                      preparations.splice(editedIndex, 1);
                    });
                  "
                  text
                  >删除</v-btn
                ><v-btn
                  color="primary"
                  text
                  @click="
                    editedIndex = preparations.indexOf(item);
                    editItems = Object.assign({}, defaultItems, item.json, {
                      id: item.id,
                    });
                    dialog = true;
                  "
                  >修改</v-btn
                ></v-card-actions
              ></v-card
            ></v-col
          ></v-row
        ></template
      ></v-data-iterator
    >
  </div>
</template>
<script>
import qs from "qs";

export default {
  data: () => ({
    dialog: false,
    preparations: [],
    editItems: {},
    editedIndex: -1,
    defaultItems: {
      cf: [],
      ybml: false,
      sfjy: false,
      zjmc: "",
      zjmcb: "",
      zjlb: "",
      gnzz: "",
      jx: "",
      gg: "",
      yfyl: "",
      blfy: "",
      zysx: "",
      tsrq: "",
      bz: "",
      zc: "",
      yxq: "",
      zxbz: "",
      pzwh: "",
      jg: "",
      nclmax: "",
      nxlmax: "",
      nxl: "",
    },
  }),
  mounted() {
    this.axios
      .get("/preparation")
      .then((e) => {
        // alert(e.data);
        let data = e.data.rows;

        if (data && Array.isArray(data)) {
          // console.log(data);
          this.preparations = data;
        }
        // alert(this.preparations);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.axios
            .put("/preparation/" + this.editItems.id, qs.stringify(this.editItems))
            .then((e) => {
              // this.preparations[this.editedIndex] = e.data;
              Object.assign(this.preparations[this.editedIndex], e.data);
              this.close();
            });
        } else {
          this.axios.post("/preparation", qs.stringify(this.editItems)).then((e) => {
            this.preparations.push(e.data);
            this.close();
          });
        }
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editItems = Object.assign({}, this.defaultItems);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
