<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">天津市医疗机构制剂调研表</div>
      <v-spacer></v-spacer>
      <!-- {{ $route.name }} -->
      <v-btn
        v-if="!$store.state.name && $route.name != 'Login'"
        text
        :to="{ name: 'Login' }"
        >登录</v-btn
      >
      <v-btn v-if="!!$store.state.name" @click="logout" text>
        <span class="mr-2">注销</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main> <router-view ref="son" /> </v-main
    ><v-footer app padless v-if="!!$store.state.name"
      ><v-card class="blue-grey lighten-5 text-center" width="100%" flat tile
        ><v-card-text color="primary">
          <!-- {{ !!$store.state.name }} -->
          <v-btn text @click="$refs.son.e1 = '机'">
            <span>医疗机构</span>
            <!-- <v-icon>mdi-history</v-icon> -->
          </v-btn>

          <v-btn text @click="$refs.son.e1 = '剂'">
            <span>制剂品种</span>
            <!-- <v-icon>mdi-heart</v-icon> -->
          </v-btn>

          <!-- <v-btn>
            <span>Nearby</span>
            <v-icon>mdi-map-marker</v-icon>
          </v-btn> -->
        </v-card-text></v-card
      ></v-footer
    >
  </v-app>
</template>

<script>
export default {
  name: "App",
  methods: {
    logout() {
      sessionStorage.setItem("token", "");
      location.reload();
    },
  },
};
</script>
