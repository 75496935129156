import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about")*/ "../views/Register.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(function(to, from, next) {
  NProgress.start();
  const token = sessionStorage.getItem("token");
  if (!token) {
    // console.log("mlb");
    if (to.path !== "/login") {
      if (to.path == "/register") {
        return next();
      } else {
        return next("/login");
      }
    }
  } else {
    // let that = this;
    window.axios
      .get("authorization")
      .then((res) => {
        // console.log(res.data.data.login_username);
        store.commit("setName", res.data.data.login_username);
        sessionStorage.setItem("token", res.data.token);
        if (to.name === "Login") {
          next({
            name: "Home",
          });
        }
      })
      .catch(() => {
        sessionStorage.setItem("token", "");
        next({
          name: "Login",
        });
      });
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
