<template>
  <v-form ref="form" @submit.prevent="submit">
    <!-- {{ editItems }} -->

    <v-text-field
      label="医疗机构名称"
      readonly
      v-model="editItems.username"
      hint="此项只读，不可修改"
      persistent-hint
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-text-field
      label="1. 医疗机构等级"
      v-model="editItems.jgdj"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-radio-group mandatory label="2. 医疗机构性质" v-model="editItems.jgxz"
      ><v-radio v-for="n in ['公立', '民营']" :key="n" :label="`${n}医院`" :value="n"
    /></v-radio-group>

    <v-radio-group mandatory label="3. 医疗机构类别" v-model="editItems.jglb"
      ><v-radio
        v-for="n in [
          '综合医院',
          '中医医院',
          '中西医结合医院',
          '专科医院',
          '社区卫生服务中心',
        ]"
        :key="n"
        :label="`${n}`"
        :value="n"
    /></v-radio-group>

    <v-text-field
      v-model="editItems.zjxk"
      label="4. 《医疗机构制剂许可证》编号"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-text-field
      label="5. 制剂室面积（平米）"
      type="number"
      v-model="editItems.zjsmj"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-text-field
      label="6. 制剂生产区面积（平米）"
      type="number"
      v-model="editItems.scqmj"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-text-field
      label="7. 院内制剂数量"
      type="number"
      v-model="editItems.ynzjsl"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-text-field
      label="8. 备案制剂数量"
      type="number"
      v-model="editItems.bazjsl"
      :rules="[(v) => !!v || '此项必填']"
    />

    <!-- {{ editItems }} -->
    <v-radio-group label="9. 院内制剂生产方式" v-model="editItems.scfs" mandatory
      ><v-radio
        v-for="(n, key) in ['自制', '委托其他医疗机构配制', '委托生产企业配制']"
        :key="n"
        :label="`${n}`"
        :value="key"
    /></v-radio-group>
    <v-text-field
      v-if="editItems.scfs === 1"
      label="《医疗机构制剂许可证》编号"
      v-model="editItems.yljgzjxkz"
      hint="委托配制机构（医疗）"
      :rules="[(v) => !!v || '此项必填']"
    /><template v-else-if="editItems.scfs === 2">
      <v-text-field
        label="《药品生产许可证》证书编号"
        v-model="editItems.ypscxkz"
        hint="委托配置机构（企业）"
        :rules="[(v) => !!v || '此项必填']" />
      <v-text-field
        label="《药品生产质量管理规范》认证证书编号"
        v-model="editItems.ypsczlglgf"
        hint="委托配置机构（企业）"
        :rules="[(v) => !!v || '此项必填']"
    /></template>

    <v-switch
      v-model="editItems.sfjs"
      :label="`10. 医院${editItems.sfjs ? '' : '不'}接受其他医疗机构制剂品种调剂使用`"
    />

    <v-switch
      v-if="editItems.sfjh == 0"
      v-model="editItems.sfjh"
      label="11. 医院无计划申报新的院内制剂"
      :false-value="0"
      :true-value="1"
    />
    <v-text-field
      v-else
      label="11. 医院计划申报新的院内制剂"
      type="number"
      v-model="editItems.sfjh"
      hint="数量,无计划填 0"
    />

    <v-text-field
      label="12. 目前医院在用协定处方数量"
      v-model="editItems.cfsl"
      type="number"
      :rules="[(v) => !!v || '此项必填']"
    />

    <v-textarea label="13. 其它" v-model="editItems.qt" />

    <v-btn block x-large color="primary" dark type="submit">保存</v-btn></v-form
  >
</template>
<script>
import qs from "qs";

export default {
  name: "HelloWorld",
  data: () => ({
    editItems: {},
  }),
  created() {
    this.axios.get("organization").then((e) => {
      this.editItems = Object.assign(
        {},
        { scfs: 0, sfjs: false, sfjh: 0, username: e.data.username },
        e.data.json
      );
      // console.log(e.data);
    });
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.axios
          .put("organization", qs.stringify(this.editItems))
          .then((e) => {
            // this.editItems = Object.assign(
            //   {},
            //   { scfs: 0, sfjs: false, sfjh: 0 },
            //   e.data.json
            // );
            console.log(e);
            alert("保存成功");
          })
          .catch((err) => {
            console.log(err);
          });
        // alert("保存成功");
      }
    },
  },
};
</script>
